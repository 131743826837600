import 'bootstrap';
import 'jquery-drawer';
import Swiper from 'swiper';
// import '@fancyapps/fancybox';
(function($) {

    var url = location.href// URLの取得
    var path = location.pathname// パスの取得
    var param = location.search// パラメーターの取得
    var anc = location.hash// ページ内アンカーの取得
    path = path.replace( 'sample_iemamori/', '' );// 本番環境では削除
    if ( url == 'http://example.com/' ){}
    if ( path.match(/cart\//) ){}
    if ( path == '/login.html' ){
        Action.checkFullPageBackgroundImage();
    }
    if ( param == '?search=123' ){}
    if ( anc == '#anchor01' ){}

    $('.drawer').drawer();

    var swiper = new Swiper('.info .swiper-container', {
        spaceBetween: 20,
        effect: 'slide',
        slidesPerView: 4,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        navigation: {
            nextEl: '#next_btn',
            prevEl: '#prev_btn',
        },
        // autoplay: {
        //     delay: 5000,
        // },
        loop:true,
        breakpoints:{
            767:{
                slidesPerView: 2,
            },
        }
    });
    
    var swiper = new Swiper('.shop_slider', {
        spaceBetween: 20,
        effect: 'slide',
        slidesPerView: 3,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        navigation: {
            nextEl: '#shop_next',
            prevEl: '#shop_prev',
        },
        // autoplay: {
        //     delay: 5000,
        // },
        loop:true,
        breakpoints:{
            640:{
                slidesPerView: 2,
            },
        }
    });
    var swiper = new Swiper('.pickup_slider', {
        spaceBetween: 65,
        effect: 'slide',
        slidesPerView: 2.7,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        navigation: {
            nextEl: '#pickup_next',
            prevEl: '#pickup_prev',
        },
        // autoplay: {
        //     delay: 5000,
        // },
        loop:true,
        breakpoints:{
            1000:{
                slidesPerView: 'auto',
            },
            1400:{
                slidesPerView: 1.7,
            },
        }
    });

})(jQuery);import 'bootstrap';

$(function(){
    $('a.page_link').on('click',function(){
        var speed = 500;
        var href= $(this).attr("href");
        var target = $(href == "#" || href == "" ? 'html' : href);
        var position = target.offset().top - 60;
        $("html, body").animate({scrollTop:position}, speed, "swing");
        return false;
    });
    $('#question dt').on('click',function(){
        $(this).next('dd').slideToggle();
        $(this).find('.plus').toggleClass('active');
    });
    $('a[href^="tel:"]').on('click',function(e){
        var width = $(window).width();
        if(width > 568){
            e.preventDefault();
        }else{
            gtag('event', 'click', {
                'event_category': 'tel',
                'event_label': '電話番号クリック'
            });
        }
    });
    $('.to_line').on('click',function(){
        gtag('event', 'click', {
            'event_category': 'line',
            'event_label': 'LINEお問い合わせ'
        });
    });
    $('.to_mail').on('click',function(){
        gtag('event', 'click', {
            'event_category': 'mail',
            'event_label': 'フォームリンク'
        });
    });
    var reason,service,fee,estimate,voice,question,area,contact;
    $(window).on('load',function(){
        reason = $('#reason').offset().top - 200;
        service = $('#service').offset().top - 200;
        fee = $('#fee').offset().top - 200;
        estimate = $('#estimate').offset().top - 200;
        voice = $('#voice').offset().top - 200;
        question = $('#question').offset().top - 200;
        area = $('#area').offset().top - 200;
        contact = $('#contact').offset().top - 200;
    });
    $(window).on('scroll',function(){
        var scroll = $(this).scrollTop();
        if(scroll > contact){
            $('header nav li').removeClass('active');
        }else if(scroll > area){
            $('header nav li').removeClass('active');
            $('header nav li#area_scroll').addClass('active');
        }else if(scroll > question){
            $('header nav li').removeClass('active');
            $('header nav li#question_scroll').addClass('active');
        }else if(scroll > voice){
            $('header nav li').removeClass('active');
            $('header nav li#voice_scroll').addClass('active');
        }else if(scroll > estimate){
            $('header nav li').removeClass('active');
        }else if(scroll > fee){
            $('header nav li').removeClass('active');
            $('header nav li#fee_scroll').addClass('active');
        }else if(scroll > service){
            $('header nav li').removeClass('active');
            $('header nav li#service_scroll').addClass('active');
        }else if(scroll > reason){
            $('header nav li').removeClass('active');
            $('header nav li#reason_scroll').addClass('active');
        }else{
            $('header nav li').removeClass('active');
        }
    });
    $('form button').on('click',function(e){
        $('#error').slideUp();
        $('#result').slideUp();
        e.preventDefault();
        var error = 0;
        $('.require_form').each(function(){
            if(!$(this).val()){
                $(this).css('background','#f1d0d5');
                error++;
            }else{
                $(this).css('background','#f4f4f4');
            }
        });
        if(!$('.require_check').prop('checked')){
            $('.require_check').addClass('unchecked');
            error++;
        }else{
            $('.require_check').removeClass('unchecked');
        }
        if(error != 0){
            $('#error').slideDown();
        }else{
            $('#form').submit();
        }
    });
    $('#form').on('submit',function(e){
        e.preventDefault();
        var form_data = $('#form').serialize();
        
        $.ajax ({
            url: './mail.php',//送信先のURL
            type: 'POST',//POST送信
            data: form_data,//送信するデータを指定
            timeout: 60000,  //タイムアウトの設定
            beforeSend: function (xhr, settings) {
                //リクエスト送信前に連続送信禁止のためボタンを非活性化する
                $('.submit-btn').attr('disabled', true);
            },
            complete: function(xhr, textStatus) {
                //リクエスト送信が完了したら、ボタンの非活性化を解除する
                $('.submit-btn').attr('disabled', false);
            }
        }).done(function(data, textStatus, jqXHR) {
            //通信成功時の処理
            gtag('event', 'send', {
                'event_category': 'form',
                'event_label': 'お問い合わせ完了'
            });
            $('#form')[0].reset();//フォームに入力している値をリセット
            $('#result').text(data);//mail.phpから返ってきたメッセージをHTMLに入れて表示する
            $('#result').slideDown();//mail.phpから返ってきたメッセージをHTMLに入れて表示する
        }).fail(function(jqXHR, textStatus, errorThrown) {
            //通信失敗時の処理
            $('#result').text('送信できませんでした');//失敗メッセージをHTMLに入れて表示する
        });
    });
});